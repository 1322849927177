import ItemsList from "./ItemsList";
import { useInView } from "react-intersection-observer";

function Tokenimics() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Анимация сработает только один раз
    threshold: 0.4, // Процент видимости компонента для триггера анимации
  });
  return (
    <>
      <section
        ref={ref}
        style={{
          opacity: inView ? 1 : 0,
        }}
        className="transition-opacity duration-1000 relative z-10 mb-32 md:mb-44 "
      >
        <h2 className="text-[60px] md:text-[124px] ml-4 mr-4 mb-16 text-center">
          TOKENOMICS
        </h2>
        <div className="md:ml-[100px] md:mr-[100px] md:flex md:flex-row-reverse justify-center gap-[36px]  items-center ">
          <div className="relative   mb-8">
            <img
              className="relative z-10 m-auto 3xl:w-[650px] pl-4 pr-4"
              src="./tokenomics.png"
              alt=""
            />
            {window.innerWidth < 700 ? (
              <>
                <img
                  className="absolute left-0 -top-28"
                  src="./tokencloud1.png"
                  alt=""
                />
                <img
                  className="absolute right-0 -bottom-12"
                  src="./tokencloud2.png"
                  alt=""
                />
              </>
            ) : (
              <>
                <img
                  className="absolute left-0 -top-28"
                  src="./tokencloud1.svg"
                  alt=""
                />
                <img
                  className="absolute right-0 -bottom-12"
                  src="./tokencloud2.svg"
                  alt=""
                />
              </>
            )}
          </div>
          <ItemsList />
        </div>
      </section>
      <div className="w-full relative">
        <img
          className="absolute min-w-[1300px] 3xl:hidden w-screen top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          src="./centerBg.png"
          alt=""
        />
      </div>
    </>
  );
}

export default Tokenimics;

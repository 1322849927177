function ItemsList() {
  const items = [
    { text: "Listing on exchanges and liquidity - 30%", color: "green" },
    { text: "Marketing & Partnerships - 10%", color: "brown" },
    { text: "Development and team - 10%", color: "yellow" },
    { text: "Reserve Fund - 6,25%", color: "blue" },
    { text: "Presale - 31,6%", color: "gray" },
    { text: "AirDrop - 9,0%", color: "orange" },
    { text: "KOL's - 3,15%", color: "red" },
  ];

  return (
    <div className="ml-4 mr-4">
      <ul className="flex flex-col items-start justify-start gap-2">
        {items.map((item, index) => (
          <li
            key={index}
            className="list flex text-[20px] md:text-[32px] items-center gap-3"
          >
            <img src={`./${item.color}.png`} className="w-[40px]" />
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ItemsList;

import { useState, useRef } from "react";
import HeaderLogo from "./HeaderLogo";
import BurgerMenu from "./BurgerMenu";
import Play from "./Play";

function HeaderMobile() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const animationTimeoutRef = useRef(null);

  const onMenu = () => {
    if (animationTimeoutRef.current) {
      clearTimeout(animationTimeoutRef.current);
    }
    setIsAnimating(true);
    animationTimeoutRef.current = setTimeout(() => {
      setIsAnimating(false);
      setIsMenuOpen(true);
    }, 1); // Время совпадает с duration-500 (0.5 секунды)
  };

  const offMenu = () => {
    if (animationTimeoutRef.current) {
      clearTimeout(animationTimeoutRef.current);
    }
    setIsAnimating(true);
    setTimeout(() => {
      setIsMenuOpen(false);
      setIsAnimating(false);
    }, 500); // Время совпадает с duration-500 (0.5 секунды)
  };

  const toggleMenu = () => {
    isMenuOpen ? offMenu() : onMenu();
  };

  const handleMenuClick = () => {
    offMenu();
  };

  return (
    <div className="mb-32 overflow-clip max-w-[100vw] relative">
      <img
        className="absolute z-0 min-w-[1000px] w-full"
        src="./topLeftBg.webp"
        alt=""
      />
      <div className="flex justify-between relative z-30 pt-9 ml-4 mr-4 mb-8">
        <HeaderLogo />
        <BurgerMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
      </div>
      {isAnimating || isMenuOpen ? (
        <div
          className={`fixed top-0 left-0 w-full h-full backdrop-blur z-20 flex flex-col items-center justify-center transition-opacity duration-500 ease-in-out ${
            isMenuOpen && !isAnimating ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className="relative w-full h-full z-[99] bg-no-repeat bg-center"
            style={{
              backgroundImage: "url('./paper.png')",
            }}
          >
            <nav className="flex flex-col items-center justify-center h-full">
              <ul className="space-y-4">
                <li className="mb-4">
                  <a href="#ABOUT US" onClick={handleMenuClick}>
                    <img
                      className="w-[138px] h-[52px]"
                      src="./about.png"
                      alt=""
                    />
                  </a>
                </li>
                <li className="mb-4">
                  <a href="#ROADMAP" onClick={handleMenuClick}>
                    <img
                      className="w-[138px] h-[52px]"
                      src="./roadmap.png"
                      alt=""
                    />
                  </a>
                </li>
                <li className="mb-4">
                  <a href="#NFT" onClick={handleMenuClick}>
                    <img
                      className="w-[138px] h-[52px]"
                      src="./nftbutton.png"
                      alt=""
                    />
                  </a>
                </li>
                <li className="mb-4">
                  <a href="#BALLS" onClick={handleMenuClick}>
                    <img
                      className="w-[138px] h-[52px]"
                      src="./balls.png"
                      alt=""
                    />
                  </a>
                </li>
                <li className="mb-4">
                  <a href="#LINKS" onClick={handleMenuClick}>
                    <img
                      className="w-[138px] h-[52px]"
                      src="./contacts.png"
                      alt=""
                    />
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    target="_blank"
                    href="https://app.ston.fi/swap?ft=TON&fa=10&tt=EQCxXcLuDo7OLyks-iGODGCpPTi5G9Yhq3DVwpjTV53xfuOu"
                    onClick={handleMenuClick}
                  >
                    <img
                      className="w-[138px] h-[52px]"
                      src="./buynow.png"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : null}
      <div className="ml-4 mr-4 relative z-10">
        <h1 className="text-[60px] pb-3 z-10 relative text-center">
          BIG WELCOME
        </h1>
        <p className="text-[20px] text-center font-bold">
          Stop playing with your balls, homie. Pick the right $BALLS, don&apos;t
          be a hamster
        </p>
      </div>
      <div className="relative z-10">
        <Play />
      </div>
    </div>
  );
}

export default HeaderMobile;

function Footer() {
  return (
    <footer
      className="relative z-10 pb-16 flex flex-col justify-center items-center"
      id="LINKS"
    >
      <h2 className="text-[60px] md:text-[124px] text-center mb-8 whitespace-pre">
        OUR&#10;RESOURCES
      </h2>
      <div className="flex mb-6 flex-row gap-3 justify-center items-center">
        <a className="button" href="https://x.com/balls_on_ton" target="_blank">
          <img src="./twitter.png" alt="" className="" />
        </a>
        <a
          className="button"
          href="https://t.me/big_balls_birds"
          target="_blank"
        >
          <img src="tg.png" alt="" className="" />
        </a>
        <a className="button" href="mailto:partnership@bigballsbirds.xyz">
          <img src="mail.png" alt="" className="" />
        </a>
      </div>
      <p className="md:w-[700px]  text-center">
        DISCLAIMER. $BALLS IS A MEME COIN WITHOUT ANY UTILITY FUNCTION. DO NOT
        RISK MONEY YOU CANNOT AFFORD TO LOSE. THE PRICE COULD INCREASE OR
        DECREASE, AND WE ARE NOT LIABLE FOR ANY FLUCTUATIONS IN THE $BALLS
        VALUE.&#10;
      </p>
      <a
        className="opacity-60"
        href="./disclaimer.pdf"
        download={"$BALLS memecoin disclaimer.pdf"}
      >
        [Read Full Disclaimer]
      </a>
    </footer>
  );
}

export default Footer;

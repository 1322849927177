import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import HeaderMobile from "./components/HeaderMobile";
import About from "./components/About";
import Roadmap from "./components/Roadmap";
import Game from "./components/Game";
import Tokenomics from "./components/Tokenomics";
import Nft from "./components/Nft";
import Token from "./components/Token";
import Footer from "./components/Footer";
import Preloader from "./components/PreLoader";
import { ParallaxProvider } from "react-scroll-parallax";
import BallsCursor from "./components/BallsCursor";
import BirdCursor from "./components/BirdCursor";

function App() {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(window.innerWidth);
    const handleLoad = () => {
      setLoading(false);
    };

    if (document.readyState === "complete") {
      setLoading(false);
    } else {
      window.addEventListener("load", handleLoad);
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  return (
    <ParallaxProvider>
      <BallsCursor />
      <BirdCursor />
      <div className="bg-primary text-white overflow-clip">
        {loading && <Preloader />}
        {!loading && (
          <>
            {isMobile ? <HeaderMobile /> : <Header />}
            <Roadmap />
            <About />
            <Game />
            <Tokenomics />
            <Nft />
            <Token />
            <Footer />
          </>
        )}
      </div>
    </ParallaxProvider>
  );
}

export default App;

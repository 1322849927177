import React, { useEffect, useRef, useState } from "react";
import skinsAssets from "../assetsConfig";

function Play({ isMobile }) {
  const staticCanvasRef = useRef(null);
  const dynamicCanvasRef = useRef(null);
  const [birdAssets, setBirdAssets] = useState([]);
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [nextBirdAssets, setNextBirdAssets] = useState([]);
  const eggAnimationStageRef = useRef(0);
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [frames, setFrames] = useState([]);
  const drawAnimationRef = useRef(false);
  const changingBirdRef = useRef(false);
  const gameWidth = isMobile ? 420 : 1000;
  const frameIndexRef = useRef(0);
  const animationFrameId = useRef(null);

  const balls1AudioRef = useRef(new Audio("/sounds/balls1.mp3"));
  const balls2AudioRef = useRef(new Audio("/sounds/balls2.mp3"));
  const balls3AudioRef = useRef(new Audio("/sounds/balls4.mp3"));
  const boomAudioRefs = useRef(
    Array.from({ length: 7 }, (_, i) => new Audio(`/sounds/boom${i + 1}.mp3`))
  );

  useEffect(() => {
    const loadFrames = async () => {
      const framePaths = skinsAssets.Animation.map(
        (frame) => `/skinsAssets/${frame}`
      );

      const framePromises = framePaths.map((src) => preloadImage(src));
      const loadedFrames = await Promise.all(framePromises);
      setFrames(loadedFrames);

      drawFirstFrame(loadedFrames);
    };

    loadFrames();
  }, []);

  const loadAssets = async () => {
    try {
      const newBird = await getRandomAssets();
      setBirdAssets(newBird);
      setLoading(false);
      drawStaticAssets(newBird);
      loadNextBirdAsset();
    } catch (error) {
      console.error("Error loading assets:", error);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      fetchClicksCount();
    }, 500);
    loadAssets();
    fetchClicksCount();

    return () => {
      clearInterval(timer);
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  const fetchClicksCount = async () => {
    try {
      const response = await fetch(
        "https://bigballsbirds.fun/api_clicker/clicks"
      );
      const data = await response.json();
      setCounter(data.count);
    } catch (error) {
      console.error("Error fetching clicks count:", error);
    }
  };

  const sendClicksCount = async () => {
    try {
      const response = await fetch(
        "https://bigballsbirds.fun/api_clicker/click",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setCounter(data.count);
      changingBirdRef.current = true;
      setBirdAssets(nextBirdAssets);
      drawStaticAssets(nextBirdAssets);
      loadNextBirdAsset();
      changingBirdRef.current = false;
    } catch (error) {
      console.error("Error incrementing click count:", error);
    }
  };

  const loadNextBirdAsset = async () => {
    try {
      const nextBird = await getRandomAssets();
      setNextBirdAssets(nextBird);
      setLoading(false);
    } catch (error) {
      console.error("Error loading next bird asset:", error);
    }
  };

  const getRandomAssets = () => {
    const keys = Object.keys(skinsAssets).filter((key) => key !== "Animation");
    const assetPromises = keys.map(async (key) => {
      const items = skinsAssets[key];
      const randomItem = items[Math.floor(Math.random() * items.length)];
      const assetPath = `${key}/${randomItem}`;
      const image = await preloadImage(`./skinsAssets/${assetPath}`);
      return { key, image };
    });

    return Promise.all(assetPromises);
  };

  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = src;
      image.onload = () => resolve(image);
      image.onerror = (error) => reject(error);
    });
  };

  const handleCanvasClick = () => {
    if (!isClickedOnce) {
      setIsClickedOnce(true);
    }
    if (changingBirdRef.current) {
      return;
    }

    if (eggAnimationStageRef.current < 3) {
      eggAnimationStageRef.current += 1;
      drawAnimationRef.current = true;
      drawDynamicAsset();
    }
  };

  const drawStaticAssets = (assets) => {
    const canvas = staticCanvasRef.current;

    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const drawOrder = ["Clothes", "Face", "Head", "Item"];
        const canvasSize = gameWidth;
        const scaleFactor = canvasSize / 3000;

        drawOrder.forEach((layer) => {
          const assetObj = assets.find((asset) => asset.key === layer);
          if (assetObj) {
            const { image } = assetObj;
            ctx.drawImage(
              image,
              0,
              0,
              image.width * scaleFactor,
              image.height * scaleFactor
            );
          }
        });
      }
    }
  };

  const drawFirstFrame = (loadedFrames) => {
    eggAnimationStageRef.current = 0;
    frameIndexRef.current = 0;
    const canvas = dynamicCanvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      const frame = loadedFrames[0];
      const canvasSize = gameWidth;
      const scaleFactor = canvasSize / 2048;
      if (frame && ctx) {
        const x = (ctx.canvas.width - frame.width * scaleFactor) / 2;
        const y = (ctx.canvas.height - frame.height * scaleFactor) / 2;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(
          frame,
          x,
          y,
          frame.width * scaleFactor,
          frame.height * scaleFactor
        );
      }
    }
  };

  const playSound = (stage) => {
    if (stage === 1) {
      balls1AudioRef.current.play();
    } else if (stage === 2) {
      balls2AudioRef.current.play();
    } else if (stage === 3) {
      const randomIndex = Math.floor(
        Math.random() * boomAudioRefs.current.length
      );
      boomAudioRefs.current[randomIndex].play();
    }
  };

  const drawDynamicAsset = () => {
    const canvas = dynamicCanvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      const frameCount = {
        0: 3,
        1: 3,
        2: 3,
        3: 10,
      };

      const drawAnimationFunc = () => {
        if (ctx) {
          const phase = eggAnimationStageRef.current;
          if (drawAnimationRef.current === true) {
            const startFrame = phase === 0 ? 0 : (phase - 1) * 3;
            const endFrame = phase !== 3 ? startFrame + frameCount[phase] : 20;
            if (frameIndexRef.current <= endFrame) {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              const frame = frames[frameIndexRef.current];
              const canvasSize = gameWidth;
              const scaleFactor = canvasSize / 2048;
              if (frame) {
                const x = (ctx.canvas.width - frame.width * scaleFactor) / 2;
                const y = (ctx.canvas.height - frame.height * scaleFactor) / 2;
                ctx.drawImage(
                  frame,
                  x,
                  y,
                  frame.width * scaleFactor,
                  frame.height * scaleFactor
                );
              }

              frameIndexRef.current += 1;

              animationFrameId.current = setTimeout(
                drawAnimationFunc,
                1000 / 15
              );
            } else if (frameIndexRef.current >= 20) {
              console.log("wtf");
              drawAnimationRef.current = false;
              drawFirstFrame(frames);
            }
            if (frameIndexRef.current === 9) {
              sendClicksCount();
            }
          }
        }
      };
      if (drawAnimationRef.current) {
        playSound(eggAnimationStageRef.current);
        drawAnimationFunc();
      }
    }
  };

  useEffect(() => {
    if (!loading && drawAnimationRef.current) {
      drawDynamicAsset();
    }
  }, [birdAssets, loading]);

  return (
    <div
      onClick={handleCanvasClick}
      className="relative z-50 select-none w-full flex flex-col items-center justify-start mb-32 md:mb-44 h-[calc(50vh_+_30px)] md:h-[calc(50vh_+_60px)] pointer-events-auto"
    >
      {loading && <div>Loading...</div>}
      <div className="relative flex justify-start items-center w-[202px] h-[32px] z-50 top-4">
        <img className="absolute" src="./earn.png" alt="" />
        <p className="relative z-50 left-4  text-gray-400">
          Blown: <span className="text-black">{counter}</span>
        </p>
      </div>
      {!isClickedOnce && (
        <h2 className="absolute z-30 top-1/2  text-[60px] md:text-[124px] text-center w-[600px] opacity-50">
          TAP TAP
        </h2>
      )}
      <img
        className="min-w-[50vh] h-[50vh]"
        style={{
          backgroundSize: "contain",
          aspectRatio: "1/1",
          position: "absolute",
          top: "55%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        src="./skinsAssets/Body/Body.png"
        alt=""
      />
      <canvas
        ref={staticCanvasRef}
        width={gameWidth}
        height={gameWidth}
        style={{
          height: "50vh",
          position: "absolute",
          top: "55%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <canvas
        ref={dynamicCanvasRef}
        width={gameWidth}
        height={gameWidth}
        style={{
          height: "50vh",
          position: "absolute",
          top: "55%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
}

export default Play;

import { useInView } from "react-intersection-observer";

function About() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Анимация сработает только один раз
    threshold: 0.3, // Процент видимости компонента для триггера анимации
  });

  return (
    <>
      <main
        ref={ref}
        style={{
          opacity: inView ? 1 : 0,
        }}
        className="transition-opacity duration-1000 mb-32 md:mb-44 relative z-10"
        id="ABOUT US"
      >
        <div className="relative mb-16 z-10 ml-4 mr-4">
          <h2 className="text-[60px] pb-3 md:text-[124px] text-center">
            ABOUT BBB
          </h2>
          <p className="text-[20px] md:text-2xl md:w-[600px] text-center m-auto">
            BIG BALLS BIRDS is the best mate of the Flappy Bird. Don&apos;t butt
            the barriers with your hypeness! Bored? Use NFT as a game skin.
          </p>
        </div>
        {window.innerWidth < 1000 ? (
          <div className="relative">
            <img
              className="relative z-10 w-full m-auto"
              src="./hood.png"
              alt=""
            />
            <img
              className="absolute top-0 left-0 z-"
              src="./hoodcloud1.png"
              alt=""
            />
            <img
              className="absolute top-0 right-0 "
              src="./hoodcloud2.png"
              alt=""
            />
          </div>
        ) : (
          <img className="m-auto" src="./desktophood.png" />
        )}
      </main>
      <div className="opacity-50 w-full relative">
        <img
          className="absolute min-w-[1300px] 3xl:hidden w-screen top-16 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          src="./centerBg.png"
          alt=""
        />
      </div>
    </>
  );
}

export default About;

import { useInView } from "react-intersection-observer";

function Nft() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Анимация сработает только один раз
    threshold: 0.4, // Процент видимости компонента для триггера анимации
  });

  return (
    <section
      ref={ref}
      style={{
        opacity: inView ? 1 : 0,
      }}
      className="transition-opacity duration-1000 relative z-10 ml-4 mr-4 mb-32 md:mb-44"
      id="NFT"
    >
      <div className="">
        <h2 className="text-center mb-[60px] text-[60px] md:text-[124px] whitespace-pre">
          {window.innerWidth > 1100 ? (
            <>SHOW THEM ALL&#10;YOUR BALLS</>
          ) : (
            <>SHOW THEM&#10;ALL YOUR&#10;BALLS</>
          )}
        </h2>
        <img src="./nft.png" alt="" className="m-auto" />
        <p className="text-center md:w-[700px] m-auto text-[20px] md:text-2xl mb-4">
          1000 hand-drawn birds, among them images of mass culture: Pepe, Doge,
          Pavel Durov. Sold out in 4 minutes.
        </p>
        <p className="text-center md:w-[700px] m-auto text-[20px] md:text-2xl mb-8">
          Just keep NFT in your wallet, earn $BALLS and partners tokens.
        </p>
        <div className="flex justify-center">
          <a
            target="_blank"
            href="https://getgems.io/collection/EQAJZy957mjfURxDg0f_8r0wLRaV02tjE6vx6NTGIlOhBVcD"
          >
            <img className="w-full md:w-[188px]" src="./buy.png" alt="" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Nft;

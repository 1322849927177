import { useInView } from "react-intersection-observer";

function Game() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Анимация сработает только один раз
    threshold: 0.4, // Процент видимости компонента для триггера анимации
  });

  return (
    <>
      <section
        ref={ref}
        style={{
          opacity: inView ? 1 : 0,
        }}
        className="transition-opacity duration-1000 mb-32 md:mb-44 relative overflow-clip"
      >
        <div className="ml-4 mr-4 mb-16 relative z-40 flex flex-col justify-center items-center">
          <h2 className="text-center pb-3 text-[60px] md:text-[124px]">
            PLAY TO EARN
          </h2>
          <p className="text-center text-[20px] mb-8 md:text-2xl md:w-[600px] m-auto">
            Pass balls between poles, compete for the title of the biggest balls
            and collect coins that will be converted into a token after listing
          </p>
          <a target="_blank" href="https://t.me/big_balls_birds_bot/BBB">
            <img className="w-20 md:w-[124px]" src="./playtoearn.png" alt="" />
          </a>
        </div>
        <div className="relative z-10">
          <img className="relative z-10 m-auto" src="./play.png" alt="" />
          {window.innerWidth < 700 ? (
            <>
              <img
                className="absolute right-0 -top-16"
                src="./gamecloud1.png"
                alt=""
              />
              <img
                className="absolute left-0 bottom-6"
                src="./gamecloud2.png"
                alt=""
              />
            </>
          ) : (
            <>
              <img
                className="absolute left-16 top-16"
                src="./gamecloud1.svg"
                alt=""
              />
              <img
                className="absolute right-4 bottom-24 "
                src="./gamecloud2.svg"
                alt=""
              />
              <img
                className="absolute right-0 -top-24"
                src="./gamecloud3.svg"
                alt=""
              />
            </>
          )}
        </div>
      </section>
      <div className="opacity-50 w-full relative">
        <img
          className="absolute min-w-[1300px] md:hidden 3xl:block  w-screen top-16 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          src="./centerBg.png"
          alt=""
        />
      </div>
    </>
  );
}

export default Game;

import React, { useEffect, useState } from "react";

function BallsCursor() {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [lerpedPosition, setLerpedPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);

  const lerp = (start, end, amount) => {
    return start + (end - start) * amount;
  };

  useEffect(() => {
    const onMouseMove = (event) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };

    const onMouseDown = () => {
      setIsVisible(true);
    };

    const onMouseUp = () => {
      setIsVisible(false);
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  return (
    <>
      <div
        className={`custom-cursor ${isVisible ? "show" : "hide"}`}
        style={{
          left: `${position.x - 15}px`,
          top: `${position.y}px`,
        }}
      />
    </>
  );
}

export default BallsCursor;

function Phase({ image, title, description, isPhase2, inView }) {
  return (
    <div
      style={{
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(50px)",
        transition: "opacity 0.5s, transform 0.5s",
      }}
      className="flex flex-col items-center justify-center"
    >
      <div className="">
        <img src={image} className="mb-5" alt={title} />
      </div>
      <div className="">
        <h3 className="text-center text-[44px]">{title}</h3>
        <p className="text-center max-w-[270px] text-[22px]">{description}</p>
      </div>
    </div>
  );
}

export default Phase;

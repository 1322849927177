import React from "react";

function BurgerMenu({ isOpen, toggleMenu }) {
  return (
    <img
      className="relative w-[50px] h-[50px] z-50"
      src={isOpen ? "./cross.png" : "./burger.svg"}
      alt="Menu"
      onClick={toggleMenu}
    />
  );
}

export default BurgerMenu;

// assetsConfig.js
const skinsAssets = {
  Balls: ["Balls.png"],
  Body: ["Body.png"],
  Clothes: [
    "Abibas/Abibas.png",
    "Cashier/Cashier.png",
    "Clown/Clown.png",
    "Crimson jacket/Crimson jacket.png",
    "Fallout/Fallout.png",
    "Gachimuchi/Gachimuchi.png",
    "Lifebuoy/Lifebuoy.png",
    "Poncho/Poncho.png",
    "Prison tattoos/Prison tattoos.png",
    "Security/Security.png",
    "Skuf/Skuf.png",
    "Smoking jacket/Smoking jacket.png",
    "TON flipper/TON flipper.png",
    "Vice City/Vice City.png",
  ],
  Face: [
    "Ballshtern tattoo/Ballshtern tattoo.png",
    "Clown makeup/Clown makeup.png",
    "Gigachad/Gigachad.png",
    "Laser Eye/Laser Eye.png",
    "Mexican makeup/Mexican makeup.png",
    "Monocle/Monocle.png",
    "Pants/Pants.png",
  ],
  Head: [
    "Bandana/Bandana.png",
    "Bangs/Bangs.png",
    "Cashier's cap/Cashier's cap.png",
    "Clown Wig/Clown Wig.png",
    "Crown/Crown.png",
    "Dreadlocks/Dreadlocks.png",
    "Emo/Emo.png",
    "Gutra/Gutra.png",
    "Harry Ballster/Harry Ballster.png",
    "Headphones/Headphones.png",
    "Jewish hat/Jewish hat.png",
    "Kokoshnik/Kokoshnik.png",
    "Newsboy cap/Newsboy cap.png",
    "Ninja/Ninja.png",
    "Pilot's hat/Pilot's hat.png",
    "Police hat/Police hat.png",
    "Sombrero/Sombrero.png",
    "Spongeballs cap/Spongeballs cap.png",
    "TON cap/TON cap.png",
    "Trump/Trump.png",
  ],
  Item: [
    "BIP39/BIP39.png",
    "Bottle/Bottle.png",
    "Dragon Lore/Dragon Lore.png",
    "Game/Game.png",
    "Hand pie/Hand pie.png",
    "Hook/Hook.png",
    "Lightsaber/Lightsaber.png",
    "Portal gun/Portal gun.png",
    "Small balls note/Small balls note.png",
    "Watch/Watch.png",
    "Water gun/Water gun.png",
  ],
  Animation: [
    "Balls/Balls.png",
    "phase1/1.png",
    "phase1/2.png",
    "phase1/3.png",
    "phase2/1.png",
    "phase2/2.png",
    "phase2/3.png",
    "phase3/1.png",
    "phase3/2.png",
    "phase3/3.png",
    "phase4/4.png",
    "phase4/5.png",
    "phase4/6.png",
    "phase4/7.png",
    "phase4/8.png",
    "phase4/9.png",
    "phase4/10.png",
    "phase4/11.png",
    "phase4/12.png",
    "phase4/13.png",
  ],
};

export default skinsAssets;

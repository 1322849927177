function NavBar() {
  return (
    <nav className="">
      <ul className="flex gap-3">
        <NavItem src="./about.png" text="ABOUT US" />
        <NavItem src="./roadmap.png" text="ROADMAP" />
        <NavItem src="./nftbutton.png" text="NFT" />
        <NavItem src="./balls.png" text="BALLS" />
        <NavItem src="./contacts.png" text="LINKS" />
      </ul>
    </nav>
  );
}
function NavItem({ src, text }) {
  return (
    <li className="">
      <a href={`#${text}`}>
        <span className="">
          <img src={src} alt="" />
        </span>
      </a>
    </li>
  );
}

export default NavBar;

import React, { useState } from "react";
import toast from "react-hot-toast";
import { useParallax } from "react-scroll-parallax";

function Token() {
  const [isGif, setIsGif] = useState(false);
  const cloudToken1 = useParallax({
    translateX:
      window.innerWidth > 1000 ? ["-350px", "0px"] : ["-450px", "100px"],
    speed: 20,
  });
  const cloudToken2 = useParallax({
    translateX:
      window.innerWidth > 1000 ? ["350px", "0px"] : ["450px", "100px"],
    speed: 20,
  });

  const handleClick = () => {
    setIsGif(true);
    setTimeout(() => setIsGif(false), 1500); // Время отображения гифки в миллисекундах
  };

  return (
    <>
      <section className="relative z-10 ml-4 mr-4 mb-32 md:mb-44" id="BALLS">
        <h2 className="text-[60px]  md:text-[124px] text-center">
          HOLD YOUR {} $BALLS
        </h2>
        <div className="">
          <div className="relative">
            <img
              src={isGif ? "./111.gif" : "./token.png"}
              alt=""
              className={`transform scale-75 m-auto relative z-10  ${
                isGif ? "md:h-[510px]" : 0
              }
              `}
              onClick={handleClick}
            />
            <div className="absolute z-0 top-0 left-0 w-full h-full">
              <img
                ref={cloudToken1.ref}
                className="absolute w-[400px] -top-0 left-[400px]"
                src="./tokencloud2.svg"
                alt=""
              />
              <img
                ref={cloudToken2.ref}
                className="absolute w-[400px] -bottom-16 right-[400px]"
                src="./tokencloud1.svg"
                alt=""
              />
            </div>
          </div>
          <p className="text-center relative z-10 text-[20px] md:text-2xl md:w-[700px] m-auto mb-8">
            $BALLS is a game token, make in-game purchases it currency. Earnings
            are directly related to the game: collect coins and invite friends
            to farm $BALLS.
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center gap-3">
          <a
            href="https://app.ston.fi/swap?ft=TON&fa=10&tt=EQCxXcLuDo7OLyks-iGODGCpPTi5G9Yhq3DVwpjTV53xfuOu"
            target="_blank"
            className=""
          >
            <img src="./get.png" alt="" className=" h-16" />
          </a>
          <a
            href="https://dexscreener.com/ton/eqaccnlquzvgcj7g1os_owxk4wxqsvbsm9d0fjlba_wykuay"
            target="_blank"
          >
            <img src="./dex.png" alt="" className=" h-16" />
          </a>
        </div>
      </section>
      <div className="opacity-60 w-full relative">
        <img
          className="absolute min-w-[1300px] 3xl:min-w-[500px]  -z-10 w-screen top-16 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          src="./centerBg.png"
          alt=""
        />
      </div>
    </>
  );
}

export default Token;

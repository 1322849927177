import { useInView } from "react-intersection-observer";
import Phase from "./Phase";

function Roadmap() {
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: ref4, inView: inView4 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const { ref: arrowRef1, inView: arrowInView1 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const { ref: arrowRef2, inView: arrowInView2 } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <>
      <section
        style={{ opacity: inView4 ? 1 : 0 }}
        className="transition-opacity duration-700 relative overflow-x-clip mb-32 md:mb-44"
        id="ROADMAP"
        ref={ref4}
      >
        <h2 className="mb-8 text-[60px] md:text-[124px] relative z-10 text-center">
          ROADMAP
        </h2>
        <div className="flex flex-col relative z-10 md:flex-row md:gap-0 md:justify-center gap-8">
          <div ref={ref1}>
            <Phase
              title="Phase 1"
              description="Launching the bot and the beta version of the game, NFT Sale, Presale and Token launch"
              image="./phase1.png"
              alt="Phase 1"
              isPhase2={false}
              inView={inView1}
            />
          </div>

          <img
            ref={arrowRef1}
            className={`hidden md:block w-32 h-[61px] mt-12 transition-transform duration-700 ${
              arrowInView1 ? "opacity-100 rotate-0" : "opacity-0 rotate-20"
            }`}
            src="./arrow1.png"
          />

          <div ref={ref2}>
            <Phase
              title="Phase 2"
              description=" $BALLS air drop for bot, chat of whales and Game release"
              image="./phase2.png"
              alt="Phase 2"
              isPhase2={true}
              inView={inView2}
            />
          </div>
          <img
            ref={arrowRef2}
            className={`hidden md:block w-32 h-[61px] mt-16 transition-transform duration-700 ${
              arrowInView2
                ? "opacity-100 rotate-0"
                : "opacity-0 rotate-[-20deg]"
            }`}
            src="./arrow2.png"
          />
          <div ref={ref3}>
            <Phase
              title="Phase 3"
              description="Staking $BALLS and BBB NFTs to get tokens from our hyped partners"
              image="./phase3.png"
              alt="Phase 3"
              isPhase2={false}
              inView={inView3}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Roadmap;

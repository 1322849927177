import HeaderLogo from "./HeaderLogo";
import NavBar from "./NavBar";
import { useParallax } from "react-scroll-parallax";
import Play from "./Play";
function Header() {
  const cloud1 = useParallax({ speed: 10 });
  const cloud2 = useParallax({ speed: 10 });
  const cloud3 = useParallax({ speed: 20 });
  const cloud4 = useParallax({ speed: 30 });
  return (
    <header className="relative z-10 h-[900px]">
      <img
        className="absolute w-screen min-w-[1300px]"
        src="./topLeftBg.webp"
        alt=""
      />
      <div className="flex justify-around items-center relative z-10 pt-9 ml-4 mr-4 mb-8">
        <HeaderLogo />
        <NavBar />
        <a
          target="_blank"
          className="button"
          href="https://app.ston.fi/swap?ft=TON&fa=10&tt=BALLS"
        >
          <img src="./buynow.png" alt="" />
        </a>
      </div>
      <div className="absolute z-20 w-full h-[900px] pointer-events-none">
        <img
          ref={cloud2.ref}
          className="absolute w-72 z-30 top-56 right-64"
          src="./cloud1.png"
          alt=""
        />
        <img
          ref={cloud1.ref}
          className="absolute z-30 bottom-52 right-20"
          src="./cloud2.png"
          alt=""
        />
        <img
          className="absolute w-[450px] top-52 left-12
        "
          ref={cloud3.ref}
          src="./gamecloud1.png"
          alt=""
        />
        <img
          ref={cloud4.ref}
          className="absolute left-64  w-72 bottom-64"
          src="gamecloud2.png"
          alt=""
        />
      </div>
      <div className="w-full flex flex-col justify-center items-center ">
        <h1 className="text-[124px] z-10 text-center">BIG WELCOME</h1>
        <p className="text-2xl z-10 text-center ">
          Stop playing with your balls, homie. Pick the right $BALLS, don&apos;t
          be a hamster
        </p>
        <div className="relative z-0">
          <Play />
        </div>
      </div>
    </header>
  );
}

export default Header;
